<template>
  <div>
    <div>
      <input
        v-model="searchQuery"
        v-sanitize
        class="search-table"
        type="search"
        :placeholder="$t('datasets.search')"
      >
    </div>
    <b-overlay
      :show="loadingList"
      :variant="'white'"
      :opacity="0.6"
      rounded="sm"
    >
      <div class="above-datasetsList">
        <button
          type="button"
          class="btn btn-primary"
          @click="$router.push({ name: 'AddDataset' })"
        >
          <b-icon-plus />
          {{ $t('datasets.add') }}
        </button>

        <Pagination
          v-if="datasetsCount >= 50 && datasetsList && datasetsList.length > 0"
          :current-page="currentPage"
          :nb-pages="Math.ceil(datasetsCount / 50)"
          class="datasetsList-pagination"
          @change-page="$event => currentPage = $event"
          v-on="$listeners"
        />
        <Multiselect
          v-model="selectedSort"
          :options="sortOptions"
          class="datasetsList-sorter"
          track-by="field"
          label="label"
          select-label=""
          selected-label=""
          deselect-label=""
          :searchable="false"
          :allow-empty="false"
        />
      </div>
      <div
        class="vstack"
        style="margin: 1rem 0;"
      >
        <div
          v-for="dataset of datasetsList"
          :key="dataset.id"
        >
          <DatasetItem
            :dataset="dataset"
            v-on="$listeners"
            @loading="$event => loadingList = $event"
            @delete="openDeleteModal"
          />
        </div> 
      </div>
      <Pagination
        v-if="datasetsCount >= 50 && datasetsList && datasetsList.length > 0"
        :current-page="currentPage"
        :nb-pages="Math.ceil(datasetsCount / 50)"
        class="datasets-list-pagination"
        @change-page="$event => currentPage = $event"
        v-on="$listeners"
      />
    </b-overlay>
    <DeleteDatasetWithResources
      :open="isDeleteDatasetWithResourceOpen"
      :dataset="datasetWithResourceModalData"
      @delete-dataset-only="deleteDataset"
      @delete-dataset-and-resources="deleteDatasetWithResources"
      @close="isDeleteDatasetWithResourceOpen = false"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import _ from 'lodash';

import DatasetItem from '@/components/Dataset/DatasetItem.vue';
import DeleteDatasetWithResources from '@/components/Modals/DeleteDatasetWithResources.vue';
import Multiselect from 'vue-multiselect';
import Pagination from '@/components/Elements/Pagination.vue';

export default {
  name: 'DatasetsList',

  components: {
    DatasetItem,
    DeleteDatasetWithResources,
    Multiselect,
    Pagination
  },

  data() {
    return {
      loadingList: false,
      searchQuery: null,
      currentPage: 1,

      selectedSort: null,
      sortOptions: [
        {
          label: 'Trier par: Dernière révision',
          field: 'last_revision_date',
          direction: '-'
        },
        {
          label: 'Trier par: Nom',
          field: 'display_name',
          direction: ''
        },
        {
          label: 'Trier par: Organisation',
          field: 'usergroup',
          direction: ''
        },
        {
          label: 'Trier par: Date de création',
          field: 'creation_date',
          direction: '-'
        },
        // {
        //   label: 'Trier par: Ressource principale',
        //   field: '',
        // direction: ''
        // },
        {
          label: 'Trier par: Publié/Dépublié',
          field: 'publish',
          direction: ''
        }
      ],

      datasetToDelete: null,
      isDeleteDatasetWithResourceOpen: false,
      datasetWithResourceModalData: {
        name: null,
        nbResources: null
      }
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset',
      'datasetsList',
      'datasetsCount',
      'searchDatasetsFilter',
      'isDatasetsListSearched'
    ]),

    sortBy() {
      return {
        direction: this.selectedSort ? this.selectedSort.direction : '',
        field: this.selectedSort ? this.selectedSort.field : null
      };
    },
  },

  watch: {
    searchQuery: _.debounce(async function(newValue) {
      this.loadingList = true;
      try {
        const searched = await this.SEARCH_DATASETS_LIST({
          text: newValue,
          types: []
        });
        if (searched) {
          this.loadingList = false;
        }
      } catch (err) {
        if (err && err.code && err.code !== 'ERR_CANCELED') {
          console.error(err);
          this.loadingList = false;
        }
      }
    }, 100),

    sortBy: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit('sort-by', newValue);
        }
      }
    }
  },

  created() {
    // Preset sort
    this.selectedSort = this.sortOptions[0];

    // Retrieve previous search query
    if (this.isDatasetsListSearched && this.$router.currentRoute.name === 'Datasets') {
      this.searchQuery = this.searchDatasetsFilter;
    }
  },

  methods: {
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapActions('datasets', [
      'SEARCH_DATASETS_LIST',
      'DELETE_DATASET',
      'GET_DATASET_DETAIL'
    ]),
    ...mapActions('resources', [
      'DELETE_RESOURCE_FROM_DATASET',
      'DELETE_RESOURCE'
    ]),

    openDeleteModal(dataset) {
      this.datasetToDelete = dataset;
      this.datasetToDelete.display_name = dataset.display_name;
      if (!dataset.mainResource && (!dataset.resource_annexes || dataset.resource_annexes.length === 0)) {
        this.OPEN_MODAL({
          modal: 'confirmation',
          open: true,
          title: this.$t('modals.deleteDataset.title'),
          content: this.$t('modals.deleteDataset.content.dataset', { dataset: dataset.display_name }),
          trigger: this.deleteDataset
        });
      } else {
        this.datasetWithResourceModalData.name = dataset.display_name;
        this.datasetWithResourceModalData.nbResources =
          (dataset.resource_annexes ? dataset.resource_annexes.length : 0) + (dataset.mainResource ? 1 : 0);
        this.isDeleteDatasetWithResourceOpen = true;
      }
    },

    async deleteDataset() {
      try {
        this.isDeleteDatasetWithResourceOpen = false;
        this.loadingList = true;
        await this.DELETE_DATASET(this.datasetToDelete);
        this.loadingList = false;
        this.$emit('get-data');
      } catch {
        this.loadingList = false;
      }
    },

    async deleteDatasetWithResources() {
      try {
        this.isDeleteDatasetWithResourceOpen = false;
        this.loadingList = true;
        await this.GET_DATASET_DETAIL(this.datasetToDelete.id);

        // Delete annexes
        if (this.currentDataset.resource_annexes && this.currentDataset.resource_annexes.length) {
          for (const annex of this.currentDataset.resource_annexes) {
            await this.DELETE_RESOURCE_FROM_DATASET({
              resourceToDatasetId: annex.resourcedataset_id,
              resource: annex
            });
            await this.DELETE_RESOURCE(annex);
          }
        }

        // Delete main resource
        if (this.currentDataset.resource_main) {
          await this.DELETE_RESOURCE_FROM_DATASET({
            resourceToDatasetId: this.currentDataset.resource_main.resourcedataset_id,
            resource: this.currentDataset.resource_main
          });
          await this.DELETE_RESOURCE(this.currentDataset.resource_main);
        }

        // Finally delete dataset
        await this.DELETE_DATASET(this.currentDataset);
        this.loadingList = false;
        this.$emit('get-data');

      } catch (err) {
        console.error(err);
        this.loadingList = false;
      }
    },
  }
};
</script>

<style lang="less" scoped>
.above-datasetsList {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .datasetsList-pagination {
    margin: 0 1rem;
  }
  .datasetsList-sorter {
    max-width: 30%;
  }
}
</style>