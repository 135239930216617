<template>
  <nav aria-label="List navigation">
    <ul class="pagination pagination-sm justify-content-center">
      <li
        class="page-item"
        :class="{ disabled: page === 1 }"
      >
        <a
          class="page-link"
          href="#"
          aria-label="Previous"
        >
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li
        v-for="(index, i) in pagination(page, nbPages)"
        :key="`${index}-${i}`"
        class="page-item"
        :class="{
          active: page === index,
          disabled: index === '...'
        }"
      >
        <a
          class="page-link"
          href="#"
          @click="changePage(index)"
        >
          {{ index }}
        </a>
      </li>
      <li
        class="page-item"
        :class="{ disabled: page === nbPages }"
      >
        <a
          class="page-link"
          href="#"
          aria-label="Next"
        >
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    nbPages: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      page: 1
    };
  },

  watch: {
    currentPage(newValue) {
      this.page = newValue;
    },
    page(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('change-page', newValue);
      }
    },
  },

  methods: {
    pagination(c, m) {
      const current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [];
      let   l;

      for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || i >= left && i < right) {
          range.push(i);
        }
      }
      for (const i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },

    changePage(num) {
      if (typeof num === 'number') {
        this.page = num;
      }
    }
  }
};
</script>

<style lang="less" scoped>
ul {
  margin-bottom: 0 !important ;
}
</style>