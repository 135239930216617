<template>
  <div
    class="dataset-item"
    :class="{
      disabled: !permissions.includes('change_dataset')
    }"
    @click="permissions.includes('change_dataset') ? $router.push({
      name: 'DatasetDetail',
      params: { id: dataset.id }
    }) : null"
  >
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <div class="datasetName">
        <span
          :id="`descriptionDataset-tooltip-target-${dataset.id}`"
          class="datasetName-display"
        >
          {{ dataset.display_name }}
        </span>
        <b-tooltip
          v-if="dataset && dataset.description"
          :target="`descriptionDataset-tooltip-target-${dataset.id}`"
          triggers="hover"
          custom-class="dataset-name-tooltip"
        >
          {{ dataset.description.slice(0, 800) }}
          <span v-if="dataset.description && dataset.description.length > 799">
            &nbsp;...
          </span>
        </b-tooltip>
        <span
          v-if="dataset && dataset.children && dataset.children.length"
          class="badge text-bg-success"
        >
          {{ dataset.children.length }} {{ $tc('words.child', dataset.children.length) }}
        </span>
      </div>
      <div class="dataset_data">
        <div class="dataset_data-infos">
          <span v-if="dataset && dataset.usergroup && dataset.usergroup.display_name">
            <b>{{ dataset.usergroup.display_name }}</b> - 
          </span>
          <span v-if="dataset && dataset.last_revision_date">
            <em>
              Mis à jour 
              <span
                v-if="$i18n.locale === 'en'"
                :id="`lastRevisionDateDataset-tooltip-target-${dataset.id}`"
              >
                {{ timeSinceEn(new Date(dataset.last_revision_date)) }} ago
              </span>
              <span
                v-else
                :id="`lastRevisionDateDataset-tooltip-target-${dataset.id}`"
              >
                il y a {{ timeSinceFr(new Date(dataset.last_revision_date)) }}
              </span>
              <b-tooltip
                :target="`lastRevisionDateDataset-tooltip-target-${dataset.id}`"
                triggers="hover"
              >
                {{ new Date(dataset.last_revision_date).toLocaleDateString() }} -
                {{ new Date(dataset.last_revision_date).toLocaleTimeString() }}
              </b-tooltip>
            </em>
          </span>
          <br>
          <span v-if="dataset && dataset.resource_main && dataset.resource_main.display_name">
            <b>Donnée : </b> {{ dataset.resource_main.display_name }} - 
          </span>
          <span>
            <b>Annexes:</b> {{
              dataset && dataset.resource_annexes && dataset.resource_annexes.length ?
                dataset.resource_annexes.length : 0
            }}
          </span>
        </div>
        <div
          class="dataset_data-actions"
        >
          <div
            class="btn-toolbar justify-content-between"
            role="toolbar"
            aria-label="Toolbar with button groups"
          >
            <div
              class="btn-group btn-group-sm"
              role="group"
              aria-label="First group"
            >
              <button
                v-if="permissions.includes('change_dataset')"
                :id="`accessDataset-tooltip-target-${dataset.id}`"
                type="button"
                class="btn btn-outline-primary"
                @click.stop="goToDatasetPermissions"
              >
                <span
                  v-if="dataset && dataset.default_permission_level > 0"
                  :id="`permissionsDataset-tooltip-target-${dataset.id}`"
                >
                  <b-icon
                    icon="search"
                    :color="dataset.default_permission_level > 0 ? '#0088ff' : '#ccc'"
                    font-scale="1"
                  />
                  <b-icon
                    icon="eye-fill"
                    :color="dataset.default_permission_level > 1 ? '#0088ff' : '#ccc'"
                    font-scale="1"
                    style="margin-left: 0.15rem;"
                  />
                  <b-icon
                    icon="cloud-arrow-down-fill"
                    :color="dataset.default_permission_level > 2 ? '#0088ff' : '#ccc'"
                    font-scale="1"
                    style="margin-left: 0.2rem;"
                  />
                </span>
                <b-icon
                  v-else
                  icon="lock"
                  alt="Icon access unlock open"
                  :color="'#0088ff'"
                  font-scale="1"
                />
              </button>
              <b-tooltip
                :target="`accessDataset-tooltip-target-${dataset.id}`"
                triggers="hover"
              >
                Gérer les droits
              </b-tooltip>
              <button
                v-if="permissions.includes('change_dataset')"
                :id="`editDataset-tooltip-target-${dataset.id}`"
                type="button"
                class="btn btn-outline-primary"
                @click.stop="editInNewTab"
              >
                <i class="bi bi-pencil text-primary" />
              </button>
              <b-tooltip
                :target="`editDataset-tooltip-target-${dataset.id}`"
                triggers="hover"
              >
                Editer
              </b-tooltip>
              <button
                v-if="permissions.includes('view_dataset')"
                :id="`visualizeDataset-tooltip-target-${dataset.id}`"
                type="button"
                class="btn btn-outline-primary"
                @click.stop="openPortal"
              >
                <i class="bi bi-book text-primary" />
              </button>
              <b-tooltip
                :target="`visualizeDataset-tooltip-target-${dataset.id}`"
                triggers="hover"
              >
                Ouvrir dans Explorer
              </b-tooltip>
              <button
                :id="`duplicateDataset-tooltip-target-${dataset.id}`"
                type="button"
                class="btn btn-outline-primary"
                @click.stop="duplicateDataset"
              >
                <i class="bi bi-files text-primary" />
              </button>
              <b-tooltip
                :target="`duplicateDataset-tooltip-target-${dataset.id}`"
                triggers="hover"
              >
                Dupliquer
              </b-tooltip>
              <button
                v-if="permissions.includes('delete_dataset')"
                :id="`deleteDataset-tooltip-target-${dataset.id}`"
                type="button"
                class="btn btn-outline-danger"
                @click.stop="$emit('delete', dataset)"
              >
                <i class="bi bi-trash-fill text-danger" />
              </button>
              <b-tooltip
                :target="`deleteDataset-tooltip-target-${dataset.id}`"
                triggers="hover"
              >
                Supprimer
              </b-tooltip>
            </div>
          </div>
          <div
            :id="`publishedDataset-tooltip-target-${dataset.id}`"
            :class="{
              disabled: !permissions.includes('change_dataset')
            }"
            class="form-check form-switch"
            @click.stop="runDatasetPublishScenario"
          >
            <input
              :id="`publication-switch-${dataset.id}`"
              class="form-check-input"
              type="checkbox"
              role="switch"
              :checked="dataset.publish"
            >
          </div>
          <b-tooltip
            :target="`publishedDataset-tooltip-target-${dataset.id}`"
            triggers="hover"
          >
            {{ dataset.publish ? 'Dépublier' : 'Publier' }}
          </b-tooltip>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { timeSinceEn, timeSinceFr } from '@/utils/date.js';

import datasetsAPI from '@/api/datasetsAPI.js';

export default {
  name: 'DatasetItem',

  props: {
    dataset: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    ...mapState('datasets', [
      'datasetsList'
    ]),

    permissions() {
      if (this.dataset && this.dataset._perms && this.dataset._perms.length) {
        return this.dataset._perms;
      }
      return [];
    }
  },

  methods: {
    timeSinceEn,
    timeSinceFr,

    ...mapActions('datasets', [
      'DUPLICATE_DATASET'
    ]),
    goToDatasetPermissions() {
      this.$router.push({
        name: 'DatasetPermissions',
        params: {
          id: this.dataset.id,
          datasetName: this.datasetsList.find(el => el.id === this.dataset.id).display_name
        }
      });
    },
    editInNewTab() {
      const routeData = this.$router.resolve({
        name: 'DatasetDetail',
        params: { id: this.dataset.id }
      });
      window.open(routeData.href, '_blank');
    },
    openPortal() {
      window.open(`${process.env.VUE_APP_PORTAIL_ROUTE}jeux-de-donnees/${this.dataset.codename}`, '_blank');
    },
    async duplicateDataset() {
      try {
        this.$emit('loading', true);
        await this.DUPLICATE_DATASET(this.dataset.id);
        this.$router.push({
          name: 'AddDataset'
        });
        this.$emit('loading', false);
      } catch {
        this.$emit('loading', false);
      }
    },

    async runDatasetPublishScenario() {
      try {
        this.loading = true;
        const scenarios = await datasetsAPI.getDatasetScenarios(this.dataset.id);
        let scenario;
        if (this.dataset.publish) {
          scenario = scenarios.find(el => el.codename === 'unpublish-dataset');
        } else {
          scenario = scenarios.find(el => el.codename === 'publish-dataset');
        }
        if (scenario && scenario.id) {
          await datasetsAPI.buildDatasetIndex(this.dataset.id, scenario.id);
          this.$emit('get-data');
        }
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },
  }
};
</script>

<style lang="less" scoped>
.dataset-item {
  border-top: solid @blue 1px;
  padding-top: 0.1rem;
  padding-bottom: 0.3rem;
  .datasetName {
    .datasetName-display {
      color: @blue;
      font-size: 1rem;
      text-overflow: ellipsis;
      white-space: pre-line;
      overflow-x: hidden;
      word-break: break-word;
    }
    .badge {
      margin: 0 0 0.2rem 0.5rem;
      vertical-align: middle;
      line-height: 0.8rem;
      font-size: 0.7rem;
    }
  }
  .dataset_data {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dataset_data-infos {
      width: 70%;
      line-height: 1.1rem;
      .badge {
        margin: 0 0 0 0.5rem;
        vertical-align: middle;
        line-height: 0.8rem;
      }
    }
    .dataset_data-actions {
      width: 30%;
      font-size: 1.1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-group {
        .btn:hover {
          .bi {
            color: #FFFFFF !important;
          }
        }
        .btn:focus {
          box-shadow: none;
        }
      }
      .form-check {
        margin: 0 0 0 0.5rem;
        padding-left: 2.5em;
      }
      .form-check-input:checked {
        background-color: #28a745;
      }
    }
  }
}
.dataset-item:not(.disabled):hover {
  cursor: pointer;
  background-color: #eef5f8;
}
.dataset-item.disabled {
  .datasetName {
    .datasetName-display {
      color: #5fa1d3;
    }
    .badge {
      opacity: 0.6;
    }
  }
  .dataset_data {
    .dataset_data-infos {
      opacity: 0.6;
    }
  }
}
</style>

<style>
.dataset-name-tooltip .tooltip-inner{
  max-width: 1200px !important;
  text-align: justify;
}
</style>